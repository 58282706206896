import WebsiteModule from "./website-module.vue";
import HomePage from "./views/home-page.vue";
import ContactPage from "./views/contact-page.vue";
import FaqsPage from "./views/faqs-page.vue";
import WorkPage from "./views/work-page.vue";

export default {
  path: "/",
  component: WebsiteModule,
  children: [
    {
      component: HomePage,
      name: "home",
      path: "/",
    },
    {
      component: ContactPage,
      name: "contact",
      path: "/contact-us",
    },
    {
      component: FaqsPage,
      name: "faqs",
      path: "/faqs",
    },
    {
      component: WorkPage,
      name: "work-show",
      path: "/works/:name",
    },
  ],
};
