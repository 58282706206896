<script setup lang="ts">
import VSection from "../components/v-section.vue";
import VButton from "../../components/v-button.vue";
import { useContactStore } from "../stores/contact-store.ts";
import { capitalize, onMounted, reactive, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

const contactStore = useContactStore();
const errors = ref(null);
const display_success = ref(false);
const initial_state = {
  name: null,
  email: null,
  message: null,
};
const form_data = reactive({ ...initial_state });

const rules = {
  name: { required },
  email: { required, email },
  message: { required },
};

const $v = useVuelidate(rules, form_data, { $lazy: true });

const sendInquiry = async () => {
  const result = await $v.value.$validate();
  if (!result) {
    // prettier-ignore
    errors.value = $v.value.$errors.map((err) => (err.$message as string).replace("Value", capitalize(err.$property))) as any;
    return;
  }
  const res = await contactStore.createInquiry(form_data);
  if (res.error) return (errors.value = res.error);
  display_success.value = true;
  Object.assign(form_data, initial_state);
};
</script>

<template>
  <VSection class="flex gap-10 flex-col lg:flex-row">
    <div class="lg:flex-grow">
      <div class="text-4xl font-bold">
        Let's <span class="text-[#008cad]">Connect</span>
      </div>
      <div class="mt-10">
        At Beantech, we thrive on turning ideas into digital reality. Whether
        you're eager to kickstart your project or still contemplating the next
        step, we're here to turn your aspirations into exceptional software
        solutions.
      </div>
      <div class="mt-5">
        Let's talk. We love collaborating and transforming visions into
        extraordinary software. Reach out if you're curious, excited, or simply
        want to explore the possibilities. Our team is ready to listen and turn
        your thoughts into incredible innovations.
      </div>
      <div class="mt-5">
        Contact us today and let's build something amazing together.
      </div>
    </div>
    <div
      class="rounded shadow-lg bg-[#212529] lg:p-10 p-5 text-white w-full min-h-[500px] max-h-screen h-full lg:min-w-[500px] flex flex-col gap-6"
    >
      <div
        class="rounded bg-red-500 bg-opacity-70 px-3 py-1 text-sm"
        v-if="errors"
      >
        <div :key="error" v-for="error in errors">&#8226; {{ error }}</div>
      </div>
      <div
        class="rounded bg-green-400 bg-opacity-70 px-3 py-1 text-sm"
        v-if="display_success"
      >
        <div class="text-lg font-semibold">Inquiry sent!</div>
        <div class="mt-1 text-sm">
          We will get back to you through your email in the next business day!
          Thank you!
        </div>
      </div>
      <div>
        <div class="text-xs font-bold whitespace-nowrap text-[#008cad]">
          Full Name
        </div>
        <input
          type="text"
          v-model="form_data.name"
          class="bg-transparent border-white outline-none border-b-[1px] w-full"
        />
      </div>
      <div>
        <div class="text-xs font-bold whitespace-nowrap text-[#008cad]">
          Email
        </div>
        <input
          type="email"
          v-model="form_data.email"
          class="bg-transparent border-white outline-none border-b-[1px] w-full"
        />
      </div>
      <div class="flex-grow flex flex-col">
        <div class="text-xs font-bold whitespace-nowrap text-[#008cad]">
          Message
        </div>
        <textarea
          v-model="form_data.message"
          class="h-full flex-grow bg-transparent border-white outline-none border-[1px] w-full resize-none overflow-y-auto p-2 text-sm mt-1 rounded"
        ></textarea>
      </div>
      <VButton class="w-fit h-fit m-auto text-sm mt-5" :click="sendInquiry"
        >Send</VButton
      >
    </div>
  </VSection>
</template>
