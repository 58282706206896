import { defineStore } from "pinia";
import { reactive } from "vue";

export const useHomeStore = () => {
  const store = defineStore("homeStore", () => {
    /////////////////////////////////
    // STATE
    /////////////////////////////////

    /////////////////////////////////
    // GETTERS
    /////////////////////////////////

    /////////////////////////////////
    // ACTIONS
    /////////////////////////////////

    return {};
  });

  return store();
};
