<script setup lang="ts">
import { ref } from "vue";
import BtLogo from "../../components/icons/bt-logo.vue";
import BurgerMenuIcon from "../../components/icons/burger-menu.vue";
import XIcon from "../../components/icons/x-icon.vue";

const show_nav = ref(false);

const displayNav = (is_show: boolean) => (show_nav.value = is_show);
</script>

<template>
  <nav
    class="w-full h-[56px] bg-white bg-opacity-80 flex justify-between p-3 sticky top-0 left-0 z-[9999]"
  >
    <router-link :to="{ name: 'home' }" class="flex items-center gap-2">
      <BtLogo class="h-[80%] w-fit" />
      <div class="text-xl mt-0.5">BeanTech</div>
    </router-link>
    <div class="sm:hidden">
      <BurgerMenuIcon class="h-full w-fit" @click="(e) => displayNav(true)" />
    </div>
    <div
      class="absolute h-screen w-screen bg-white top-0 left-0"
      v-if="show_nav"
    >
      <div class="w-full mt-5">
        <XIcon
          class="w-[20px] h-[20px] ml-auto mr-5"
          @click="(e) => displayNav(false)"
        />
      </div>
      <div
        class="flex flex-col w-fit h-fit m-auto text-center text-xl gap-5 mt-10"
      >
        <router-link
          :to="{ name: 'home', hash: '#services' }"
          @click="(e) => displayNav(false)"
        >
          Services
        </router-link>
        <router-link
          :to="{ name: 'home', hash: '#works' }"
          @click="(e) => displayNav(false)"
        >
          Works
        </router-link>
        <router-link :to="{ name: 'faqs' }" @click="(e) => displayNav(false)">
          FAQs
        </router-link>
        <router-link
          :to="{ name: 'contact' }"
          @click="(e) => displayNav(false)"
        >
          Contact Us
        </router-link>
      </div>
    </div>
    <div class="gap-4 hidden sm:flex">
      <router-link :to="{ name: 'home', hash: '#services' }">
        Services
      </router-link>
      <router-link :to="{ name: 'home', hash: '#works' }"> Works </router-link>
      <router-link :to="{ name: 'faqs' }"> FAQs </router-link>
      <router-link :to="{ name: 'contact' }"> Contact Us </router-link>
    </div>
  </nav>
</template>
