<script setup lang="ts">
import VSection from "../components/v-section.vue";
import VImage from "../../components/v-image.vue";
import WorkShowcase from "../components/work-showcase.vue";
import ggg from "../../../assets/clients/ggg.png";
import GGGML2 from "../../../assets/projects/GGG-MASTERLIST2.png";
import GGGML1 from "../../../assets/projects/GGG-MASTERLIST1.png";
import GGGSOA1 from "../../../assets/projects/GGG-SOA1.png";
import GGGSOA2 from "../../../assets/projects/GGG-SOA2.png";
import dmmi from "../../../assets/clients/dmmi.png";
import DMMI1 from "../../../assets/projects/DMMI1.png";
import DMMI2 from "../../../assets/projects/DMMI2.png";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const work = ref({});
const is_ready = ref(false);

const works = [
  {
    name: "GGG Manpower",
    label: "Web System",
    description:
      "GGG Manpower Services operates as a specialized agency facilitating rapid employment and deployment within the labor workforce. Operating from strategic branches in Bacolod and Cebu, their core expertise lies in efficiently supplying skilled labor to major soft drink corporations, established hotel chains, and a diverse array of businesses.",
    image: ggg,
    systems: [
      {
        name: "Masterlist System",
        images: [GGGML1, GGGML2],
        label: "Web System",
        description:
          "The Masterlist system is an advanced web-based platform designed to centralize and streamline employee profiles while implementing an efficient framework for record-keeping updates and deployment. By automating numerous manual tasks, it significantly alleviates workload pressures and minimizes input time. This responsive system offers seamless access through any internet connection, ensuring flexibility and accessibility for optimized operational efficiency.",
      },
      {
        name: "Financial Tracker System",
        images: [GGGSOA1, GGGSOA2],
        label: "Web System",
        description:
          "The Financial Tracker System revolutionizes traditional bookkeeping by digitizing billing statements and Statement of Accounts. This digital transformation enhances accessibility, providing clear and easily accessible information about current financials. Through its responsive design, this system ensures seamless access from any internet connection, facilitating swift and convenient financial management.",
      },
    ],
  },
  {
    name: "DMMI Manufacturing",
    label: "Website",
    description:
      "DMMI Manufacturing specializes in the meticulous design and fabrication of an array of electrical solutions, including Powder Coated/Stainless Steel Electrical Panel Boards, Low Voltage Switch Gear, Medium Voltage Switch Gear, Cable Tray Systems, and various other high-quality electrical products. Their expertise lies in crafting precise and durable solutions to meet diverse electrical infrastructure needs with a focus on reliability and innovation.",
    image: dmmi,
    systems: [
      {
        name: "Website",
        images: [DMMI1, DMMI2],
        label: "Website",
        description:
          "The DMMI Website stands as a comprehensive multi-page platform dedicated to spotlighting the exceptional electrical engineering services and products offered by DMMI Manufacturing. Prospective clients gain access to a wealth of company information, including intricate details about the company's services, extensive contact information, and an insightful portfolio showcasing previous exemplary works, empowering visitors to explore the full spectrum of DMMI's expertise and offerings.",
      },
    ],
  },
];

onMounted(async () => {
  const client_name = route.params.name as string;
  const current_work_index = works.findIndex(
    (w) => w.name.replace(/\s/g, "").toLowerCase() === client_name.toLowerCase()
  );

  if (current_work_index === -1) return;

  work.value = works[current_work_index];
  is_ready.value = true;
});
</script>

<template>
  <VSection class="pt-5" v-if="is_ready">
    <div
      class="w-full h-fit lg:p-10 p-5 flex bg-[#008cad] rounded text-white flex-col-reverse lg:flex-row gap-10"
    >
      <div class="lg:flex-grow">
        <div class="text-4xl font-semibold text-center lg:text-left">
          {{ work.name }}
        </div>
        <div
          class="text-[#008cad] px-3 mt-3 rounded-[50px] bg-white w-fit mx-auto lg:mx-0"
        >
          {{ work.label }}
        </div>
        <div class="mt-10 text-justify">
          {{ work.description }}
        </div>
      </div>
      <div
        class="p-5 mx-auto min-w-[250px] min-h-[250px] max-w-[250px] max-h-[250px] bg-white rounded-full overflow-hidden flex justify-center items-center"
      >
        <VImage
          :image="work.image"
          class="bg-white object-contain h-full w-full"
        />
      </div>
    </div>
    <div class="mt-10">
      <div class="text-3xl font-bold text-center lg:text-left">
        Our <span class="text-[#008cad]">Works</span>
      </div>
      <div class="flex flex-col gap-16 mt-5">
        <WorkShowcase
          :key="index"
          v-for="(system, index) in work.systems"
          :work="system"
        />
      </div>
    </div>
  </VSection>
  <VSection v-else>
    <div class="font-semibold text-4xl w-fit h-fit m-auto">
      Work not Found (404)
    </div>
    <div class="mt-5 w-fit mx-auto">
      Are you sure you are on the right link?
    </div>
  </VSection>
</template>
