import { createRouter, createWebHistory } from "vue-router";
import WebsiteRoutes from "./modules/website/website-router";

const routes = [WebsiteRoutes, { path: "/:pathMatch(.*)*", redirect: "/" }];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (!to.hash) return { top: 0, behavior: "smooth" };
    const element = document.querySelector(to.hash);
    if (element) {
      const offset = 56; // Adjust this value to set your desired offset
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const desiredOffset = absoluteElementTop - offset;

      return { top: desiredOffset, behavior: "smooth" };
    }
  },
});

export default router;
