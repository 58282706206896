<script setup lang="ts">
import { defineProps, onMounted, ref } from "vue";
import LeftArrowHead from "./icons/left-arrow-head.vue";
import gsap from "gsap";
import VImage from "./v-image.vue";

const props = defineProps<{
  images: [];
}>();

const image_elements = ref([]);
const current_index = ref(0);

const slide = (direction: number) => {
  let new_index = current_index.value + direction;
  // prettier-ignore
  if(direction === -1) new_index = new_index < 0 ? image_elements.value.length - 1 : new_index
  // prettier-ignore
  if(direction === 1) new_index = new_index === image_elements.value.length ? 0 : new_index

  const tl = gsap.timeline();
  tl.to(image_elements.value[current_index.value], {
    opacity: 0,
    duration: 1,
  }).fromTo(
    image_elements.value[new_index],
    { opacity: 0 },
    { opacity: 1 },
    `-=${0.5}`
  );

  current_index.value = new_index;
};

onMounted(() => {
  gsap.set(image_elements.value, { opacity: 0 });
  gsap.set(image_elements.value[current_index.value], { opacity: 1 });
});
</script>

<template>
  <div class="relative rounded overflow-hidden w-full h-full">
    <VImage
      :key="index"
      v-for="(image, index) in images"
      :image="image"
      class="w-full h-full absolute top-0 left-0 !object-cover"
      @img_ref="(e) => (image_elements[index] = e)"
    />
    <button
      @click="(e) => slide(-1)"
      class="w-[35px] absolute top-[50%] -translate-y-[50%] left-5 rounded-lg bg-black bg-opacity-60 p-2 hover:translate-y-[-52%] active:transition-none active:translate-y-[-48%]"
    >
      <LeftArrowHead class="w-full h-full fill-gray-300" />
    </button>
    <button
      @click="(e) => slide(1)"
      class="w-[35px] absolute top-[50%] -translate-y-[50%] right-5 rounded-lg bg-black bg-opacity-60 p-2 hover:translate-y-[-52%] active:transition-none active:translate-y-[-48%]"
    >
      <LeftArrowHead class="w-full h-full fill-gray-300 rotate-180" />
    </button>
  </div>
</template>
