<script setup lang="ts">
import { defineProps, ref } from "vue";
import VLoading from "./icons/v-loading.vue";

const props = defineProps<{
  click?: any;
}>();

const is_loading = ref(false);

const onClick = async () => {
  if (!props.click) return;

  is_loading.value = true;
  await props.click();
  is_loading.value = false;
};
</script>

<template>
  <button
    @click="onClick"
    :disabled="is_loading"
    class="px-5 py-2 text-lg bg-[#008cad] text-white rounded-lg font-semibold relative"
    :class="[
      is_loading
        ? 'bg-gray-500'
        : 'hover:translate-y-[-1px] active:transition-none active:translate-y-px',
    ]"
  >
    <div :class="[is_loading ? 'opacity-0' : '']">
      <slot />
    </div>
    <VLoading
      class="w-[60%] h-[60%] top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] absolute"
      v-show="is_loading"
    />
  </button>
</template>
