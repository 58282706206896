<script setup lang="ts">
import { defineProps, onMounted, ref, defineEmits } from "vue";

const props = defineProps<{
  image: string;
}>();

const img = ref(null);
const emit = defineEmits(["img_ref"]);

onMounted(() => {
  emit("img_ref", img.value);
});
</script>

<template>
  <img
    :src="image"
    alt=""
    class="w-full h-full rounded-lg bg-[#eaeaea] object-fill"
    ref="img"
  />
</template>

function emit(arg0: string, value: null) { throw new Error("Function not
implemented."); }
