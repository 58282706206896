import axios from "axios";
import { defineStore } from "pinia";

const API_URL = process.env.VUE_APP_API_URL;

const handle_error = (error: any) => {
  console.error(error);
  if (error?.response?.data) return error.response.data;
  return error;
};

const createService = (config: { base_url: string; method: string }) => {
  return async (path: string, payload = {}) => {
    const instance = axios.create({
      baseURL: config.base_url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    try {
      // prettier-ignore
      const res = await (instance as any)[config.method.toLowerCase()](path, payload);
      return [null, res.data];
    } catch (error) {
      return [error, null];
    }
  };
};

export const useContactStore = () => {
  const store = defineStore("contactStore", () => {
    const config = {
      base_url: API_URL,
    };
    const post = createService({ ...config, method: "POST" });

    /////////////////////////////////
    // STATE
    /////////////////////////////////

    /////////////////////////////////
    // GETTERS
    /////////////////////////////////

    /////////////////////////////////
    // ACTIONS
    /////////////////////////////////
    const actions = {
      createInquiry: async (payload: object) => {
        const [err, res] = await post("/inquiries", { inquiry: payload });

        if (err) return handle_error(err);
        return res;
      },
    };

    return {
      ...actions,
    };
  });

  return store();
};
