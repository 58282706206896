<script setup lang="ts">
import VSection from "../components/v-section.vue";
import VImage from "../../components/v-image.vue";
import WorkCard from "../components/work-card.vue";
import VButton from "../../components/v-button.vue";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { onMounted, ref } from "vue";
import { useHomeStore } from "../stores/home-store";

// Images
import DMMI1 from "../../../assets/projects/DMMI1.png";
import GGGML2 from "../../../assets/projects/GGG-MASTERLIST2.png";
import GGGSOA1 from "../../../assets/projects/GGG-SOA1.png";
import ggg from "../../../assets/clients/ggg.png";
import dmmi from "../../../assets/clients/dmmi.png";
import Api1 from "../../../assets/api.png";
import Api2 from "../../../assets/api2.png";
import Api3 from "../../../assets/api3.png";
import Mobile1 from "../../../assets/mobile.png";
import Mobile2 from "../../../assets/mobile2.png";
import Mobile3 from "../../../assets/mobile3.png";
import BtLogo from "../../components/icons/bt-logo.vue";
import MissionFull from "../../../assets/mission-full.png";
import Mission from "../../../assets/mission.png";
import Team from "../../../assets/team.png";

const homeStore = useHomeStore();

const website_service_images = ref([]);
const api_service_images = ref([]);
const mobile_service_images = ref([]);
const top_images = ref([]);

function gsapAnimation() {
  const services = [
    top_images.value,
    website_service_images.value,
    api_service_images.value,
    mobile_service_images.value,
  ];

  gsap.registerPlugin(ScrollTrigger);
  gsap.set(services, { opacity: 0 });
  services.forEach((service, service_index) => {
    // const parentElement = service[0].parentElement.parentElement;
    // if (service_index > 0) {
    //   gsap.set(parentElement, { opacity: 0 });
    // }
    // gsap.to(parentElement, {
    //   position: "fixed",
    //   top: "0",
    //   right: "0",
    //   height: "100vh",
    //   paddingRight: "5rem",
    //   scrollTrigger: {
    //     trigger: parentElement,
    //     start: "top 180px",
    //     toggleActions: "restart reverse restart reverse",
    //     markers: true,
    //   },
    // });
    const tl = gsap.timeline({ repeat: -1 });

    service.forEach((web_image, index) => {
      // prettier-ignore
      const nextImage = service[(index + 1)] || service[0]

      tl.to(web_image, {
        opacity: 1,
        duration: 1,
      })
        .to(web_image, {
          opacity: 1,
          duration: 1,
        })
        .to(web_image, {
          opacity: 0,
          duration: 1,
        });

      if (index !== service.length - 1) {
        tl.fromTo(nextImage, { opacity: 0 }, { opacity: 1 }, `-=${0.5}`);
      }
    });
  });
}

onMounted(async () => {
  gsapAnimation();
});
</script>

<template>
  <VSection class="text-[#212529] pt-0 lg:min-h-[calc(100vh-56px)]">
    <div class="flex justify-center sm:gap-3 gap-1 items-center">
      <BtLogo class="h-[2rem] w-[2rem] min-h-[2rem] min-w-[2rem]" />
      <div class="text-[2rem] font-[400] mt-0.5">BeanTech</div>
    </div>
    <div class="text-sm text-gray-500 text-center whitespace-nowrap">
      Where We Nurture Your Ideas Into Reality
    </div>
    <div
      class="flex xl:mt-14 mt-10 flex-col-reverse xl:gap-20 gap-8 lg:flex-row lg:gap-16"
    >
      <div
        class="lg:w-[50%] lg:h-fit lg:my-auto w-full text-center lg:text-justify"
      >
        <div class="text-4xl font-medium">
          <span class="text-[#008cad]">INNOVATE</span> with us
          <br />
          Through <span class="text-[#008cad]">CODE</span>
        </div>
        <div class="xl:mt-10 text-lg mt-8 text-justify">
          At BeanTech, we're the architects of innovation, turning your concepts
          into thriving realities. With a passion for nurturing ideas, we
          collaborate closely to transform visions into powerful software
          solutions. Beyond development, we're dedicated partners on your
          journey to success, crafting, refining, and bringing your ideas to
          life, one line of code at a time.
        </div>
        <router-link :to="{ name: 'home', hash: '#mission' }">
          <VButton class="mt-10 !bg-[#212529] rounded-[50px]"
            >Learn More</VButton
          >
        </router-link>
      </div>
      <div class="lg:w-[50%] w-full">
        <div
          class="relative h-[300px] sm:h-[360px] md:h-[400px] lg:h-[360px] lg:top-[50%] lg:translate-y-[-50%]"
        >
          <VImage
            :image="GGGML2"
            class="absolute"
            @img_ref="(e) => (top_images[0] = e)"
          />
          <VImage
            :image="Api3"
            class="absolute"
            @img_ref="(e) => (top_images[1] = e)"
          />
          <VImage
            :image="Mobile3"
            class="absolute"
            @img_ref="(e) => (top_images[2] = e)"
          />
        </div>
      </div>
    </div>
  </VSection>
  <VSection
    id="mission"
    class="relative bg-[#212529] text-white font-sans !w-full sm:py-20 md:py-30 lg:min-h-[calc(100vh-56px)] lg:flex lg:items-center"
  >
    <div
      class="lg:w-[40%] lg:bg-transparent lg:mx-0 lg:p-0 lg:text-justify h-fit relative z-10 bg-[#212529] py-10 px-5 sm:p-10 text-center w-fit rounded bg-opacity-95 sm:w-[500px] mx-auto"
    >
      <div class="text-4xl font-bold">
        Our <span class="text-[#008cad]">Mission</span>
      </div>
      <div class="lg:mt-14 text-xl leading-8 mt-10">
        We will achieve your <span class="text-[#008cad]">Vision</span> through
        our <span class="text-[#008cad]">Mission</span> to transform your ideas
        into a digital identity that conveys itself distinctly to your
        customers. We value solutions that are evident in optimizing efficiency
        for your business.
      </div>
    </div>
    <div class="lg:w-[60%] lg:block sm:hidden absolute top-0 right-0 h-full">
      <VImage :image="Mission" class="bg-transparent" />
    </div>
    <div class="w-full lg:hidden absolute top-0 right-0 h-full">
      <VImage :image="MissionFull" />
    </div>
  </VSection>
  <VSection id="services">
    <div class="text-4xl font-bold text-center">
      We <span class="text-[#008cad]">Develop</span>
    </div>
    <VSection class="!px-0 lg:mt-14 !w-full">
      <div class="flex flex-col-reverse lg:gap-20 gap-10 lg:flex-row">
        <div class="lg:w-[50%] h-fit my-auto">
          <div
            class="text-2xl font-semibold text-white rounded-lg w-fit py-1 px-5 bg-sky-400"
          >
            Websites
          </div>
          <div class="text-xl mt-5 leading-8">
            We specialize in crafting responsive websites and dynamic web
            systems, meticulously designed to streamline and elevate your
            business functions while remaining faithful to your unique brand
            identity. Our dedicated focus is on empowering enterprises to
            seamlessly communicate their services and products to their
            customers, ensuring a compelling and effective digital presence
          </div>
        </div>
        <div class="lg:w-[50%]">
          <div
            class="relative h-[300px] sm:h-[360px] md:h-[400px] lg:h-[360px] lg:top-[50%] lg:translate-y-[-50%]"
          >
            <VImage
              :image="DMMI1"
              class="absolute"
              @img_ref="(e) => (website_service_images[0] = e)"
            />
            <VImage
              :image="GGGML2"
              class="absolute"
              @img_ref="(e) => (website_service_images[1] = e)"
            />
            <VImage
              :image="GGGSOA1"
              class="h-full absolute !object-contain"
              @img_ref="(e) => (website_service_images[2] = e)"
            />
          </div>
        </div>
      </div>
    </VSection>
    <VSection class="!px-0 lg:mt-14 !w-full">
      <div class="flex flex-col-reverse lg:gap-20 gap-10 lg:flex-row">
        <div class="lg:w-[50%] h-fit my-auto">
          <div
            class="text-2xl font-semibold text-white rounded-lg w-fit py-1 px-5 bg-sky-400"
          >
            Restful APIs
          </div>
          <div class="text-xl mt-5 leading-8">
            We specialize in developing robust RESTful APIs tailored to
            precisely meet your frontend application requirements. Our API
            solutions not only grant seamless access to databases but also
            ensure a responsive and tailored approach, addressing our clients'
            diverse needs with precision and efficiency.
          </div>
        </div>
        <div class="lg:w-[50%]">
          <div
            class="relative h-[300px] sm:h-[360px] md:h-[400px] lg:h-[360px] lg:top-[50%] lg:translate-y-[-50%]"
          >
            <VImage
              :image="Api3"
              class="absolute"
              @img_ref="(e) => (api_service_images[0] = e)"
            />
            <VImage
              :image="Api2"
              class="absolute"
              @img_ref="(e) => (api_service_images[1] = e)"
            />
            <VImage
              :image="Api1"
              class="absolute"
              @img_ref="(e) => (api_service_images[2] = e)"
            />
          </div>
        </div>
      </div>
    </VSection>
    <VSection class="!px-0 lg:mt-14 !w-full">
      <div class="flex flex-col-reverse lg:gap-20 gap-10 lg:flex-row">
        <div class="lg:w-[50%] h-fit my-auto">
          <div
            class="text-2xl font-semibold text-white rounded-lg w-fit py-1 px-5 bg-sky-400"
          >
            Mobile Applications
          </div>
          <div class="text-xl mt-5 leading-8">
            We specialize in engineering vibrant, fully responsive mobile
            applications meticulously designed for both Android and iOS
            platforms. Our expertise extends beyond front-end excellence,
            encompassing robust backend APIs and database integration to ensure
            a comprehensive, seamless, and optimized mobile experience.
          </div>
        </div>
        <div class="lg:w-[50%]">
          <div
            class="relative h-[300px] sm:h-[360px] md:h-[400px] lg:h-[360px] lg:top-[50%] lg:translate-y-[-50%]"
          >
            <VImage
              :image="Mobile1"
              class="absolute"
              @img_ref="(e) => (mobile_service_images[0] = e)"
            />
            <VImage
              :image="Mobile2"
              class="absolute"
              @img_ref="(e) => (mobile_service_images[1] = e)"
            />
            <VImage
              :image="Mobile3"
              class="absolute"
              @img_ref="(e) => (mobile_service_images[2] = e)"
            />
          </div>
        </div>
      </div>
    </VSection>
  </VSection>
  <VSection
    class="lg:flex lg:items-center lg:justify-between lg:bg-[#212529] text-white relative !w-full sm:py-20 md:py-30"
  >
    <div
      class="lg:w-[45%] lg:p-0 lg:pt-5 lg:mb-10 lg:bg-transparent lg:mx-0 lg:text-justify relative z-10 bg-[#212529] py-10 px-5 sm:p-10 text-center w-fit rounded bg-opacity-95 sm:w-[500px] mx-auto"
    >
      <div class="text-4xl font-bold">
        Let's <span class="text-[#008cad]">Connect</span>
      </div>
      <div class="lg:mt-14 text-xl leading-8 mt-10">
        Embark on your software journey with Beantech! Whether you're eager to
        start or contemplating your next move, we're here to transform your
        ideas into remarkable software solutions. Let's collaborate and innovate
        together. Reach out today to begin crafting your digital vision into
        reality."
      </div>
      <router-link :to="{ name: 'contact' }">
        <VButton class="mt-10">Contact Us</VButton>
      </router-link>
    </div>
    <div class="lg:w-[50%] w-full h-full absolute top-0 left-0 lg:static">
      <VImage :image="Team" class="lg:rounded-lg" />
    </div>
  </VSection>
  <VSection class="!min-h-fit !pb-24" id="works">
    <div class="text-4xl font-bold text-center">
      Our <span class="text-[#008cad]">Works</span>
    </div>
    <div
      class="flex justify-center gap-[100px] mt-14 flex-col w-fit w-full mx-auto md:flex-row items-center"
    >
      <router-link :to="{ name: 'work-show', params: { name: 'gggmanpower' } }">
        <WorkCard :image="ggg" name="GGG Manpower" label="Web System" />
      </router-link>
      <router-link
        :to="{ name: 'work-show', params: { name: 'dmmimanufacturing' } }"
      >
        <WorkCard :image="dmmi" name="DMMI Manufacturing" label="Website" />
      </router-link>
    </div>
  </VSection>
</template>
